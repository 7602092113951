import { Button } from "@mui/material";
import React from "react";
import { navigate } from "gatsby";

const Portfolio = () => {
  const handleNavigate = () => {
    navigate("/cases?category=web-development&stackTechnology=react");
  };
  return (
    <div>
      Profile
      <Button onClick={handleNavigate}>Click</Button>
    </div>
  );
};

export default Portfolio;
